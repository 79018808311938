:root {
  --bsuBlue: #0033A0;
  --bsuOrange: #D64309;
  --grey: #3F4444;
  --coolGrey: #53565a;
  --lightGrey: #97999B;
  --lighterGrey: #c8c9c7;
  --nearWhite: #dbdcdb;
  --lighestGrey: #F6F7F9;
  --white: #FFFFFF;
  --black: #000000;
  --warningRed: #ff0000;
}

@font-face {
  font-family: 'Gotham';
  src: url(../fonts/Gotham-Book.otf) format("opentype");
}

@font-face {
  font-family: 'Gotham';
  src: url(../fonts/Gotham-MediumItalic.otf) format("opentype");
  font-style: italic;
}

h1, h2, h3, h4, h5, button {
  font-family: 'Gotham', sans-serif !important;
}

body {
  margin: 0;
  font-family: 'Garamond', sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a{
  text-decoration: none;
  color: var(--white);
}

textarea {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  width: 100%;
  border-color: var(--lighterGrey);
}

table{
  width: 100%;
  text-align: center;
}

/****Basic Formatting****/
.boxBorder{
  border:1px solid;
  border-color: var(--bsuBlue);
  box-shadow: 7px 6px 3px 5px var(--coolGrey);
  border-radius: 15px;
}

/*General Text*/
.blackFont{
  color: var(--black);
}

.greyFont{
  color: var(--lightGrey);
}

.redFont{
  color: red;
}

.textMargin{
  margin: 10px;
}

.topMargin{
  padding-top: 10px;
}

.bottomMargin{
  padding-bottom: 250px;
}

.leftText{
  text-align: left;
}

.rightText{
  text-align: right;
}

.centerText{
  text-align: center;
}

.noLinkDecorBlackFont{
  text-decoration: none;
  color: var(--black);
}

.noLinkDecorWhiteFont{
  text-decoration: none;
  color: var(--white);
  position: relative;
}

.noLinkDecorWhiteFont::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 4px;
  border-radius: 4px;
  background-color: var(--white);
  bottom: 0;
  left: 0;
  transform-origin: right;
  transform: scaleX(0);
  transition: transform .3s ease-in-out;
}

.noLinkDecorWhiteFont:hover::before {
  transform-origin: left;
  transform: scaleX(1);
}

.smallText{
  font-size: smaller;
}

.bigText{
  font-size: larger;
}

.textHover{
  margin-right: 35px;
}

.textHover:hover{
  color: var(--bsuOrange);
}

.modalTextAway{
  margin-left: 20%;
}

/*Admin Account Dash */
.inputText {
  background-color: transparent !important;
  border: none;
  text-align: center;
}
/*Lists to make grey square boxes*/
.listBoxes{
  list-style-type: none;
  background-color: var(--nearWhite);
  border-radius: 15px;
}

.list{
  margin: 0;
  padding: 0;
}

/*General Buttons*/
.orangeButton{
  background-color: var(--bsuOrange);
  border-radius: 15px;
  border-color: var(--bsuOrange);
  color: var(--white);
  font-size: 12px;
  border:none;
  height: 30px;
  width: 80px;
}

.orangeButton:hover{
  background-color: #ae3606;
}

.blueButtonNormal{
  background-color: var(--bsuBlue);
  border-radius: 15px;
  border-color: var(--bsuBlue);
  color: var(--white);
  font-size: 12px;
  border:none;
  height: 30px;
  width: 80px;
}

.blueButtonNormal:hover{
  background-color: #03205f;
}

.blueButton{
  background-color: var(--bsuBlue);
  border-radius: 20px;
  border-color: var(--bsuBlue);
  color: var(--white);
  font-size: 12px;
  border:none;
  height: 40px;
  width: 130px;
}

.blueButton:hover{
  background-color: #03205f;
}

.whiteButton{
  background-color: var(--white);
  border-radius: 15px;
  border-color: var(--white);
  font-size: 15px;
  border:none;
  height: 30px;
  width: 80px;
}

.whiteButton:hover{
  background-color: var(--lightGrey);
  border-radius: 15px;
  border-color: var(--lightGrey);
}

/****Home Page CSS****/
.container.homePage{
  height: 100% !important;
  padding-bottom: 20px;
}

.homePage h1{
  font-size: 40px;
}
.homePage p{
  font-size: 16px;
}

.buttonHome{
  background-color: var(--bsuOrange);
  color: var(--white);
  border-radius: 35px;
  border: 0px;
  padding: 20px;
  font-size: 16px;
}
@media (max-width: 1366px){
  .homePage h1{
    font-size: 50px;
  }
  .homePage p{
    font-size: 26px;
  }
  
  .buttonHome{
    font-size: 26px;
  }
}

.buttonHome:hover{
  background-color: #ae3606;;
}

.img {
  width: 100%;
  height: auto;
}

.imgTitle{
  width: 10%;
  height: 10%;
  margin-top: 10px;
}

/****Sign-in Page CSS****/
.signInBox{
  left: 0;
  background-color: var(--white);
  color: var(--black);
  position: relative;
}

.informationBox{
  right: 0;
  background-color: var(--bsuOrange);
  color: var(--white);
  background-size:100% auto; 
  background-repeat: no-repeat; 
  background-position: center;
}
@media (max-width: 1575px){
  .informationBox{
    background-size:150% auto; 
  }
  .signInBox h1{
    font-size: 50px;
  }
  .signInBox input {
    font-size: 22px;
    width: 60%;
  }
  .signInBox button{
    font-size: 22px;
  }
  .signInBox p{
    font-size: 20px;
  }
}
@media (max-width: 1050px){
  .informationBox{
    background-size:170% auto; 
  }
}

.border{
  width:16em; height:100%;
  border-radius: 50px;
  background-color: var(--white);
  box-shadow: 0px 6px 15px 5px var(--black);

  position: absolute;
  top: 50%; left: -170px;
  transform: translateY(-50%);
  z-index: 5;
}

/*Add top 0/z-index to hide NavBar*/
.split {
  height: 100%;
  width: 50%;
  position: fixed;
  z-index: 1;
  top: 0;
  overflow-x: hidden;
  padding-top: 20px;
}

/*Text*/
.textInstructions{
  font-size: 20px !important;
  margin: 150px;
  margin-left: 200px;
  margin-top: 160px;
}

.textMarginTop{
  margin-top: 20%;
}

.textMarginBottom{
  margin-bottom: 15%;
}

.textAlignBottom{
  margin-left: 25px;
  margin-top: 55%;
}

/*Buttons*/
.blueSubmitButton{
  width:50%;
  background-color: var(--bsuBlue);
  border: 0px;
  padding: 10px;
  border-radius: 15px;
  color: white;
  font-size: 12px;
}
.blueSubmitButton:hover{
  background-color: #03205f;
}

.noButtonCover{
  background-color: transparent;
  border: 0px;
  color: var(--black);
  font-size: 10px;
}

.transparent{
  background-color: transparent;
  color: var(--white);
  border: 0px;
}

.inputRounded{
  border-color: var(--black);
  background-color: transparent;
  border-radius: 5px;
  width: 50%;
  color:var(--black);
  font-size: 15px;
  padding: 9px;
}

/**Judge Page CSS (judges.js)**/
/*Buttons*/
.noMargin{
  margin-right: 12px;
}

.col-12.d-flex.align-items-start{
  padding: 0 !important;
}

.paddingRight{
  padding-right: 1em;
}

.paddingLeft{
  padding-left: 1em;
}

.TeamInfoTable{
  width: 100%;
  border-radius: 0.5em;
}

.TeamInfoTable tr{
  width: 100%;
}

.rightPadding.bigText{
  display: flex;
  justify-content: right !important;
  align-items: center;
  width: 100%;
}
.rightPadding.bigText b{
  padding: .5em;
}

.marginTableJudge.noButtonCover.button{
  width: 45px;
  float: right;
}

.textWidth.textMargin{
  margin-left: 15px;
}

.button2{
  position: relative;
  width: 100%;
  border: 0;
  border-radius: 0.5em;
  background-color: transparent;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  display: flex;
}

.button2:active,
.on2 {
  top: 2px;
  left: 1px;
  border-radius: 0.5em;
  box-shadow: none;
  background-color: var(--bsuOrange);
}

.button {
  position: relative;
  margin: 0.5em;
  padding: 0.5em 1em;
  border: 0;
  border-radius: 0.5em;
  background-color: transparent;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  background-color: var(--bsuBlue);
}

.button:active,
.on {
  top: 2px;
  left: 1px;
  box-shadow: none;
  background-color: var(--bsuBlue);
}

.rateButton {
  float:left;
  width: 30px;
  height: 25px;
  position: relative;
  background-color: var(--bsuOrange);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

.rateButton label,
.rateButton input {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: var(--white);
  text-align: center;
  cursor: pointer;
  height: 1.55rem;
  width: 1.85rem;
}

.rateButton input[type="radio"] {
  opacity: 0.011;
  z-index: 100;
}

.rateButton input[type="radio"]:checked + label{
  background: var(--bsuBlue);
  cursor: pointer;
}

.rateButton label {
  cursor: pointer;
  z-index: 90;
  line-height: 1.8em;
  height: 1.55rem;
  width: 1.8rem;
}

/*Background*/
.blueBackground{
  box-shadow: 1px 4px 5px 1px var(--coolGrey);
  color: var(--white);
  background-color: var(--bsuBlue);
}

.lightGreyBackground{
  border-bottom: 10px;
  color: var(--black);
  background-color: var(--lighestGrey);
  border-radius: 15px 15px 0px 0px;
  margin:4px;
  padding-top: 5px;
  border-bottom: 2px solid var(--lighterGrey);
  width: 100%;
}

.lightGreyBackgroundBottom{
  color: var(--black);
  background-color: var(--lighestGrey);
  border-radius: 0px 0px 15px 15px;
  margin:4px;
  padding-top: 10px;
  border-top: 2px solid var(--lighterGrey);
  width: 100%;
}

.placeholderTop{
  border-bottom: 10px;
  color: var(--black);
  background-color: var(--lighestGrey);
  border-radius: 15px 15px 0px 0px;
  margin:4px;
  padding-top: 100px;
  border-bottom: 2px solid var(--lighterGrey);
  width: 100%;
}

.placeholderBottom{
  color: var(--black);
  background-color: var(--lighestGrey);
  border-radius: 0px 0px 15px 15px;
  margin:4px;
  padding-top: 100px;
  border-top: 2px solid var(--lighterGrey);
  width: 100%;
}

.marginTableJudge{
  width: 100%;
}

.editButton{
  background-color: transparent;
  border: 0px;
}

.centered {
  text-align: center;
}
.header {
  display: flex;
  align-items: center;
  justify-content: center;
}
.deleteButton {
  background-color: transparent;
  border: 0px;
  border: 3px solid #ff0000;
  border-radius: 20px;
  font-weight: bold;
  color: var(--black);
  margin: 1.6rem 0.3rem 2rem 01.2rem;
  padding: 0.2rem .7rem;
}
.deleteButton:hover {
  background-color: #e04a4a69;
}

.saveOrangeButton{
  background-color: var(--bsuOrange);
  color: var(--white);
  border-radius: 15px;
  border: 0px;
  padding: 15px;
  box-shadow: 0px 0px 5px 1px var(--coolGrey);
}

.saveOrangeButton:hover{
  background-color: #ae3606;
}

.saveOrangeButton:active {
  background-color: var(--bsuOrange);
  color: var(--white);
  border-radius: 15px;
  border: 0px;
  padding: 15px;
  box-shadow: 0px 0px 5px 1px var(--coolGrey);
  transform: translateY(4px);
}

.textWidth{
  width: 180px;
}

/*Admin Page CSS*/
.fourSquare{
  background-color: var(--lighestGrey);
  box-shadow: 0px 3px 5px 1px var(--lightGrey);

  border-radius: 15px;
  margin: 20px;
  margin-bottom: 2% !important;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  height: 50%;
}

.fourSquare:hover{
  box-shadow: 0px 1px 3px 1px var(--lightGrey);
}

.fourSquareBarrier{
  padding: 15px;
}

.settingFourSquare{
  margin-left: 8% !important;
}

.textBox {
  width: 100%;
  height: 50px;
  border: none;
  overflow: hidden;
  padding: 5px;
  text-align: center;
}

.textBox > span {
  display: inline-block;
  white-space: nowrap;
  transition-timing-function: linear;

  transition: 1s;
}

.textBox:hover span {
  transform: translateX(calc(200px - 100%));
}

.altTextBox{
  padding-top: 3px;
  text-align: center;
}

/*Button*/
.sendCodeButton{
  background-color: var(--bsuOrange);
  border-radius: 15px;
  border-color: var(--bsuOrange);
  color: var(--white);
  font-size: 12px;
  border:none;
  height: 30px;
  width: 130px;
}

.sendCodeButton:hover{
  background-color: #ae3606;
}

.overlayButton{
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 1;
  background-color: var(--bsuBlue);
  color: var(--white);
  border-radius: 100px;
  border: 0px;
  padding: 15px;
  padding-left: 25px;
  padding-right: 25px;
  margin: 15px;
}

.overlayButton:hover{
  background-color: #03205f;
}

.tabFix {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
}

.floatright {
  margin-left: 10%;
}
.nav_title {
  font-size: 2.5em;
  margin-top: .7rem;
}
.logout-btn{
  background-color: var(--bsuOrange);
  color: var(--white);
  border-radius: 100px;
  border: 0px;
  padding: 10px;
  padding-left: 30px;
  padding-right: 30px;
}

/*Text*/
.tableView{
  background-color: var(--lighestGrey);
  margin: 15px;
  border-radius: 25px;
  width: 100%;
  z-index: -1;
}

.tableView td{
  padding: 30px;
}

.tableView tr:hover{
  background-color: var(--white);
}

.tableView th{
  border-bottom: 1px solid var(--grey);
  padding: 10px;
}

.rightTextAdmin{
  margin-left: 150px;
}

.rightSearchBoxAdmin{
  margin-left: 120px;
}

/*Nav Bar*/
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  border-bottom: 3px solid var(--bsuOrange) !important;
  color: var(--bsuOrange) !important;
  background-color: var(--lighestGrey) !important;
}

.nav-link{
  color: var(--black) !important;
  background-color: var(--lighestGrey) !important;
}

.input-search{
  height: 50px;
  width: 94%;
  border-style: none;
  padding: 10px;
  font-size: 18px;
  letter-spacing: 2px;
  outline: none;
  border-radius: 50px;
  transition: all .5s ease-in-out;
  background-color: var(--nearWhite);
  padding-right: 40px;
  color: var(--black);
}

.btn-search{
  width: 17%;
  height: 50px;
  border-style: none;
  font-size: 20px;
  font-weight: bold;
  outline: none;
  cursor: pointer;
  border-radius: 50%;
  position: absolute;
  right: 0px;
  color:var(--black) ;
  background-color: transparent;
  pointer-events: painted;  
}

/**Add Event**/
.greyBox{
  background-color: var(--nearWhite);
  border-radius: 15px;
  padding: 15px;
  padding-bottom: 200px;
}

/*Add Tables*/
.viewTable {
  background: var(--lightGrey);
  align-items: center;
  margin: 0px;
  margin-bottom: 1em;
}

.tableOptions {
  margin:auto;
  width: 60%;
}

.tableFormatting {
  table-layout: fixed;
  background-color: var(--bsuBlue);
  color: var(--white);
  border-radius: 1em;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width:100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 2;
}

.modal-main {
  position:fixed;
  background: var(--lightGrey);
  width: 80%;
  height: auto;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
  z-index: 2;
  padding: 15px;
  border-radius: 15px;
  overflow: scroll;
}

.modal-delete {
  position:fixed;
  background: var(--lightGrey);
  width: 40%;
  height: auto;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
  z-index: 2;
  padding: 15px;
  padding-bottom: 2em;
  border-radius: 15px;
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}


.team-list-container {
  margin-bottom: 8rem;
  display: inline;
}
.team-score {
  font-size: 24px;
}

.downloadButton {
  font-size: 18px;
}

.team-school {
  margin-top: 2rem;
}
.reveal-schools-btn {
  display: inline;
  border: none;
  border-radius: 20px;
  padding: 8px;
  background-color:#D64309;
  color:white;
}

.discussion-header {
  display: inline;
}

.smallInput{
  padding-top: 15px;
  width: 500px;
  border: none;
  border-bottom: 2px solid var(--lightGrey) !important;
  background-color: var(--lighestGrey);
}

.warningText{
  font-style: italic;
  padding-top: 15px;
  width: 500px;
  border: none;
  border-bottom: 2px solid var(--warningRed) !important;
}

.reuploadButton{
  background-color: transparent;
  border-radius: 15px;
  color: var(--bsuOrange);
  font-size: 12px;
  border:1px solid var(--bsuOrange);
  height: 30px;
  width: 150px;
}

.reuploadButton:hover{
  background-color: var(--bsuOrange);
  color: var(--white);
}

.eventTextMargin{
  margin-right: 430px;
  font-size: 12px !important;
}

.eventTextMarginSpec{
  margin-right: 375px;
  font-size: 12px !important;
}

.eventTextMarginSpec2{
  margin-right: 360px;
  font-size: 12px !important;
}

.eventTextMarginSpec3{
  margin-right: 280px;
  font-size: 12px !important;
}

.cancelButton{
  background-color: var(--white);
  color: var(--bsuBlue);
  border-color: var(--bsuBlue);
  border: 1px solid;
  border-radius: 15px;
  padding-left: 20px;
  padding-right: 20px;
}

.cancelButton:hover{
  background-color: var(--bsuBlue);
  color: var(--white)
}

.nextButton{
  background-color: var(--bsuBlue);
  color: var(--white);
  border-color: var(--bsuBlue);
  border: 0px;
  border-radius: 15px;
  padding-left: 20px !important;
  padding-right: 20px !important;
  padding: 3px;
}

.nextButton:hover{
  background-color: #03205f;
}

.vl{
  border-right: 1px solid;
  border-color: var(--lightGrey);
}

.buttonTags{
  background-color: var(--bsuBlue);
  border-radius: 15px;
  border: 1px solid var(--bsuBlue);
  color: var(--white);
  width:70px;
  font-size: 12px;
  display:table-cell;
}

.hideDisplay{
  display:none;
}

.modal-body {
  max-height: calc(100vh - 210px);
  overflow-y: auto;
}

button{
  background-color: transparent;
  border: 0px;
  color: var(--white);
}

.tabFix {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
}

.judge-code-table th {
  color: white;
  text-shadow: 1px 1px 8px rgb(65, 65, 65);
}
.judge-code-table td {
  border: 1px solid white;
  color: white;
  text-shadow: 1px 1px 8px rgb(65, 65, 65);
}
.judge-code-table {
  max-width: 30em;
  margin: auto;
}
.preview-event-logo {
  width: 10%;
  height: 10%;
  border: black 5px solid;
  margin: 1rem;
}
.event-logo {
  width: 50%;
  height: 50%;
  border: black 5px solid;
  margin-left: 25%;
}

.event-logo-text {
  text-align: center;
}
.error-message {
  color: red;
}